import { useEffect } from "react";
import Heading from "../components/Utils/Heading";
import { getProject } from "../redux/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import Project from "../components/Page/Project";
import { Link, useParams } from "react-router-dom";
import { HOME_ROUTE, PROJECTS_ROUTE } from "../utils/router";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
import { slugToOriginalText } from "../utils/helper";
import BreadCrump from "../assets/banner/breadcrump.jpg";
const City = () => {
  const { city } = useParams();
  const { loading, allProjects } = useSelector(({ homeRedux }) => homeRedux);
  const dispatch = useDispatch();
  useEffect(() => {
    if (allProjects.length === 0) {
      dispatch(getProject());
    }
  }, [allProjects]);
  if (!city) return <></>;
  return (
    <div>
      <PageHead
        title={slugToOriginalText(city) + " Projects" + seoData.concat.title}
        description={seoData.home.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">{`${slugToOriginalText(
            city
          )} Projects`}</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>

              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={PROJECTS_ROUTE} className="opacity-60">
                  Projects
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                {slugToOriginalText(city)}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container overflow-hidden my-5 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text={`${slugToOriginalText(city)} Projects`}
          content="The latest properties are listed below that can be your future home. Lets see what fit for you."
        />
        <Project
          loading={loading}
          projects={allProjects.filter(
            (project) =>
              project.location.districts.name.toLowerCase() ===
              slugToOriginalText(city).toLowerCase()
          )}
        />
      </div>
    </div>
  );
};

export default City;
