// Heading.js

const Heading = ({ level, text, className, content, aos }) => {
  const HeadingTag = `h${level || 1}`;
  return (
    <div className={`mx-auto max-w-screen-xl ${className}`}>
      <header className="text-center">
        <HeadingTag data-aos={aos} className={"text-xl md:text-2xl font-bold"}>
          {text}
        </HeadingTag>
        {content && (
          <p
            data-aos={aos}
            className="mx-auto mt-4 max-w-md text-x2s md:text-sm text-gray-500"
          >
            {content}
          </p>
        )}
      </header>
    </div>
  );
};

export default Heading;
