import { Link } from "react-router-dom";
import { IoLocationOutline, IoCallOutline } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import { IoMdTimer } from "react-icons/io";
import FloatingShareButton from "../Utils/FloatingShareButton";
import ReraImage from "../../assets/images/rera-banner.png";
import {
  ABOUT_ROUTE,
  MISSION_VISION_ROUTE,
  PRIVACY_ROUTE,
  PROJECT_STATUS_ROUTE,
  REFUND_ROUTE,
} from "../../utils/router";
const Footer = () => {
  return (
    <div>
      <FloatingShareButton />
      <div className="py-2 px-2 rounded bg-primary-100 flex items-center justify-center gap-4">
        <img src={ReraImage} alt="rera-banner" className="rounded-full h-20" />
        <p className="font-bold text-xs">BRERAA41740/40/A-465/2022 </p>
      </div>
      <footer className="flex flex-col items-center pt-12 bg-primary-900 text-surface   lg:text-left">
        <div className="grid md:grid-cols-1 lg:grid-cols-4 mx-2 gap-5 items-start justify-center text-center">
          <div>
            <h4 className="text-white">QUICK LINKS</h4>
            <ul className="flex flex-col gap-1 py-3">
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={MISSION_VISION_ROUTE}>Mission & Vision</Link>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={PROJECT_STATUS_ROUTE("Upcoming")}>
                  Upcoming Projects
                </Link>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={PROJECT_STATUS_ROUTE("Running")}>
                  Running Projects
                </Link>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={PROJECT_STATUS_ROUTE("Completed")}>
                  Completed Projects
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-white">INFORMATION</h4>
            <ul className="flex flex-col gap-1 py-3">
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={ABOUT_ROUTE}>About Us</Link>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={REFUND_ROUTE}>Refund Policy</Link>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <Link to={PRIVACY_ROUTE}>Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2">
            <h4 className="text-white">CONTACT US</h4>
            <ul className="flex flex-col  gap-1 py-3">
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <div className="flex items-center">
                  <IoLocationOutline className="text-white text-x2s md:text-xs  mr-1" />
                  <span className="text-white text-x2s md:text-xs  ">
                    <b> Kolkata Office :</b> Shiv Green India Infra Developers
                    Pvt. Ltd. 1409, Maduradaha, Near Anandapur P.S., Kolkata -
                    700107
                  </span>
                </div>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <div className="flex justify-center items-center">
                  <IoLocationOutline className="text-white text-x2s md:text-xs  mr-1" />
                  <span className="text-white text-x2s md:text-xs  ">
                    <b> Patna Office :</b> 402, Surya Bageshwari, West Boring
                    Canal Road, Patna - 800001
                  </span>
                </div>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <div className="flex justify-center items-center">
                  <IoCallOutline className="text-white text-x2s md:text-xs  mr-1" />
                  <span className="text-white text-x2s md:text-xs  ">
                    +91-7061777111
                  </span>
                </div>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <div className="flex justify-center items-center">
                  <MdMarkEmailUnread className="text-white text-x2s md:text-xs  mr-1" />
                  <span className="text-white text-x2s md:text-xs  ">
                    info@shivgreenindia.com
                  </span>
                </div>
              </li>
              <li className="text-x2s md:text-xs text-white hover:text-primary-500">
                <div className="flex justify-center items-center">
                  <IoMdTimer className="text-white text-x2s md:text-xs  mr-1" />
                  <span className="text-white text-x2s md:text-xs  ">
                    Mon - Sun / 9:00 AM - 8:00 PM
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full text-white text-xs md:text-sm p-4 text-center">
          © 2023 Copyright: Shivgreen India Infra Developers
        </div>
      </footer>
    </div>
  );
};

export default Footer;
