import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../redux/homeSlice";
import apiRequest from "../utils/apiRequest";
import { FaStar } from "react-icons/fa";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const FindPlot = () => {
  const { projects } = useSelector(({ homeRedux }) => homeRedux);
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState();
  const [plots, setPlots] = useState();

  useEffect(() => {
    if (projects.length === 0) {
      dispatch(getProject());
    }
  }, []);
  const getProjectDetail = async (id) => {
    try {
      const response = await apiRequest.getPlotList(id);
      if (response.data.response) {
        setResponseData(response.data.plotList);
        setPlots(response.data.plotList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePlotSearch = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setPlots(responseData);
    } else {
      setPlots(
        responseData.filter((plot) =>
          plot.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <>
     <PageHead
        title={seoData.findPlot.title}
        description={seoData.findPlot.description}
      />
      <div className="container md:mx-auto pb-10 primaryShadow rounded-[10px] my-10">
        <h4 data-aos="flip-up" className="text-lg font-bold my-3">
          Find Your Plot
        </h4>
        <div>
          <select
            type="text"
            id="id"
            onChange={(e) => getProjectDetail(e.target.value)}
            className="bg-white h-9 w-full px-4 group focus:outline-none focus:border-primary-500 border-[2px] border-slate-500 text-xs mb-3"
            placeholder="Search Here"
          >
            <option disabled={true} selected>
              Choose Project
            </option>
            {projects.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.property_name}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <input
            type="text"
            id="plotName"
            onChange={handlePlotSearch}
            className="bg-white h-8 w-full px-4 group focus:outline-none focus:border-primary-500 border-[2px] border-slate-500 text-xs mb-3"
            placeholder="Enter Plot Name"
            required
          />
        </div>
        <div className="flex flex-wrap justify-center">
          {plots &&
            plots.map((plot, index) => <DetailBox key={index} plot={plot} />)}
        </div>
      </div>
    </>
  );
};

export default FindPlot;
const DetailBox = ({ plot }) => {
  return (
    <div
      data-aos="flip-up"
      className="py-2 relative px-4 border-slate-300 border-[1px] text-center text-white w-[90px]"
      style={{
        backgroundColor: ` ${
          plot.status == "Investment"
            ? "#b2102f"
            : getPlotStatusColor(
                getPlotStatus(plot.status, plot.booking?.booking_detail)
              )
        }`,
      }}
    >
      {plot.investment != null || plot.status == "Investment" ? (
        <div className="star-container p-2">
          <div className="star-mark">
            <FaStar className="text-sm text-accent-500" />
          </div>
        </div>
      ) : (
        ""
      )}
      <h2 className="mb-2 text-x2s">{plot.name}</h2>
      <p className="mb-1 text-x2s">
        {`   ${parseFloat(plot.length).toFixed(2)} / ${parseFloat(
          plot.width
        ).toFixed(2)}`}
      </p>
      <p className="mb-1 text-x2s">
        {plot.total_area}
        {getFirstDigitFromString(plot.unit.name)}
      </p>
      <p className="mb-1 text-x2s">
        {getPlotStatus(plot.status, plot.booking?.booking_detail)}
      </p>
    </div>
  );
};
function getFirstDigitFromString(str) {
  return str.charAt(0);
}
function getPlotStatus(status, bookingDetail = "") {
  if (!bookingDetail || bookingDetail.status == "Rejected") {
    switch (status) {
      case "Sold":
        return "Booked";
      case "Booking Done":
        return "Booked";
      case "Hold":
        return "Hold";
      case "Registry Done":
        return `Registry Done`;
      default:
        return "Available";
    }
  } else {
    switch (bookingDetail?.status) {
      case "Registry Done":
        return "Registry Done";
      case "Hold":
        return "Hold";
      case "Rejected":
        return "Available";
      case "Verified":
        return "Booked";
      default:
        return "Booked";
    }
  }
}
function getPlotStatusColor(status) {
  switch (status) {
    case "Hold":
      return "grey";
    case "Investment":
      return "green";
    case "Booked":
      return "red";
    case "Registry <br> Done":
      return "blue";
    case "Registry Done":
      return "blue";
    default:
      return "green";
  }
}
