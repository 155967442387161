import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../utils/apiRequest";
export const getProject = createAsyncThunk("getProject", async (thunkAPI) => {
  try {
    const response = await apiRequest.getProject();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const homeSlice = createSlice({
  name: "header",
  initialState: {
    projects: [],
    allProjects: [],
    feedback: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.projects = action.payload?.projects;
      state.allProjects = action.payload?.all;
      state.feedback = action.payload?.feedback;
      state.loading = false;
    });
    builder.addCase(getProject.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default homeSlice.reducer;
