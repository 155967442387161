
const baseUrl = process.env.REACT_APP_BASE_URL + "file/uploads/";
export function getAssetUri(folder, filename) {
  return baseUrl + btoa(folder) + "/" + btoa(filename);
}
export function createSlug(input) {
  return input
    .toLowerCase()
    .replace(/[^a-z0-9-\s]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");
}
export function slugToOriginalText(slug) {
  let text = slug.replace(/-/g, " ");
  text = text.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
  return text;
}
export function convertYouTubeUrlToEmbedUrl(url) {
  const urlObj = new URL(url);
  let videoId;
  if (urlObj.searchParams.get("v")) {
    videoId = urlObj.searchParams.get("v");
  } else {
    const pathSegments = urlObj.pathname.split("/");
    videoId = pathSegments[pathSegments.length - 1];
  }

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    throw new Error("Invalid YouTube URL");
  }
}
export function formatAmount(num = null) {
  if (num !== null && num !== undefined && num !== 0) {
      let ext = "";
      let number_of_digits = countDigit(num);
      let divider;

      if (number_of_digits > 3) {
          if (number_of_digits % 2 !== 0) {
              divider = getDivider(number_of_digits - 1);
          } else {
              divider = getDivider(number_of_digits);
          }
      } else {
          divider = 1;
      }

      let fraction = num / divider;

      if (number_of_digits === 4 || number_of_digits === 5) {
          ext = " k";
      }
      if (number_of_digits === 6 || number_of_digits === 7) {
          ext = " Lac";
      }
      if (number_of_digits === 8 || number_of_digits === 9) {
          ext = " Cr";
      }

      fraction = fraction.toFixed(2);
      return fraction + ext;
  }
}

function countDigit(num) {
  return num.toString().length;
}

function getDivider(number_of_digits) {
  switch (number_of_digits) {
      case 4:
      case 5:
          return 1000;
      case 6:
      case 7:
          return 100000;
      case 8:
      case 9:
          return 10000000;
      default:
          return 1;
  }
}