import Heading from "../components/Utils/Heading";
import { HOME_ROUTE } from "../utils/router";
import {Link} from "react-router-dom";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const RefundPolicy = () => {
  return (
    <>
      <PageHead
        title={seoData.refund.title}
        description={seoData.refund.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">Refund Policy</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                Refund Policy
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="primaryShadow text-sm my-10 py-10 px-10 mx-3 rounded">
          <h2 data-aos="flip-up" className="text-2xl font-bold text-black pb-5">
            Shivgreen India Non-Refundable Policy
          </h2>
          <p data-aos="flip-up my-3">
            Thank you for your interest in purchasing real estate property
            through our agency. To maintain transparency and clarify our
            policies, we have established the following non-refundable terms and
            conditions:
          </p>

          <ul className="px-4 space-y-5 mt-10">
            <li data-aos="flip-up" className="my-1 flex items-center">
              <svg
                class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <p>
                <strong>Non-Refundable Deposits:</strong> Any deposits made
                towards the purchase of real estate property are non-refundable.
                Once a deposit is made, it can be canceled The amount will not
                be refunded, it can be transferred to any other person according
                to the market price.
              </p>
            </li>
            <li data-aos="flip-up" className="my-1 flex items-center">
              <svg
                class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <p>
                <strong>Property Sale Agreements:</strong> Upon signing a
                property sale agreement, the purchase is considered final. it
                can be canceled The amount will not be refunded, it can be
                transferred to any other person according to the market price
              </p>
            </li>
            <li data-aos="flip-up" className="my-1 flex items-center">
              <svg
                class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <p>
                <strong>Exceptions:</strong> Refunds may only be considered in
                exceptional cases, subject to legal obligations or statutory
                rights.
              </p>
            </li>
            <li data-aos="flip-up" className="my-1 flex items-center">
              <svg
                class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <p>
                <strong>Contact for Support:</strong> For any queries or
                concerns regarding property purchases, please contact our
                customer service team for assistance.
              </p>
            </li>
            <li data-aos="flip-up" className="my-1 flex items-center">
              <svg
                class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <p className="py-2">
                <strong>Policy Updates:</strong> We reserve the right to modify
                or update this non-refundable policy at any time. Any changes
                will be communicated accordingly.
              </p>
            </li>
          </ul>
          <p data-aos="flip-up" className="py-2">
            By pursuing a real estate property purchase through our agency, you
            acknowledge that you have read, understood, and agreed to our
            non-refundable policy.
          </p>
          <p data-aos="flip-up" className="py-2">
            We appreciate your understanding and support in adhering to these
            terms. For further inquiries or clarification, please don’t hesitate
            to contact our customer service team for assistance.
          </p>
          <p data-aos="flip-up" className="py-2">
            Thank you for considering our real estate services.
          </p>
          <p data-aos="flip-up" className="py-2">
            Real Estate Agency Management Team
          </p>
        </div>
      </div>
      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="What We Provide?"
          content="As Shiv Green India Infra Developer’s Pvt. Ltd. We Provide Various Services Listed Below."
        />
        <div className="lg:grid-cols-4 grid-cols-1 sm:grid-cols-2 grid gap-10 mt-12 py-5 flex-wrap">
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img
              src={"/icons/present.png"}
              fetchPriority="low"
              height={200}
              width={200}
            />
            <p className="uppercase">Presenting Your Property</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img
              src={"/icons/rent.png"}
              fetchPriority="low"
              height={200}
              width={200}
            />
            <p className="uppercase">Renting Or Selling</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img
              src={"/icons/house.png"}
              fetchPriority="low"
              height={200}
              width={200}
            />
            <p className="uppercase">Property Exchange</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img
              src={"/icons/buy.png"}
              fetchPriority="low"
              height={200}
              width={200}
            />
            <p className="uppercase">Buying A Property</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default RefundPolicy;
