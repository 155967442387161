import * as Yup from "yup";

export const contactFormSchema = Yup.object().shape({
  name: Yup.string().min(3).max(100).required("name field is required"),
  message: Yup.string().min(3).max(200).required("name field is required"),
  email: Yup.string()
  .min(3)
  .max(100)
  .email("invalid email address")
  .required("email field is required"),
  phone: Yup.string()
  .matches(/^\d{10}$/, "Mobile No. must be exactly 10 digits")
  .required("Mobile No. field is required"),
});
