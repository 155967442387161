
const PropertyShimmer = () => {
  return (
    <div className="gap-5 relative mb-5 pb-3 primaryShadow rounded-lg bg-white">
      <div className="absolute top-5 left-5 bg-primary-300 text-white text-sm h-7 w-[60px] py-1 px-2 rounded z-10">
      </div>
      <div className="h-60 relative overflow-hidden rounded bg-slate-200">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
      </div>
      <div className="flex flex-col px-3 pt-3">
        <h1 className="text-sm font-bold pr-14 h-4 bg-slate-200 w-full mb-1"></h1>
        <div className="flex mt-1 items-center gap-2 w-[300px] h-3 bg-slate-200"></div>
        <div className="flex gap-4 items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="h-3 bg-slate-200 w-[100px] mt-2"></span>
          </div>
          <div className="w-[100px] h-8 bg-primary-100 rounded mt-2"></div>
        </div>
      </div>
    </div>
  );
};

export default PropertyShimmer;
