
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../Home/categorySelection.css";
import "../Home/slick.css";
import { CustomNextArrow, CustomPrevArrow } from "./Associate";
import { convertYouTubeUrlToEmbedUrl } from "../../utils/helper";

const Feedback = ({ videos }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative container">
      <Slider {...settings}>
        {videos.map((item, index) => {
          return (
            <div key={index} className="px-4">
              <div className="h-60 overflow-hidden rounded">
                <iframe
                  width="100%"
                  height="300px"
                  src={convertYouTubeUrlToEmbedUrl(item.link)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Feedback;
