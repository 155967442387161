import Heading from "../components/Utils/Heading";
import { HOME_ROUTE } from "../utils/router";
import { Link } from "react-router-dom";
import ContactPage from "../components/Page/Contact";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const Contact = () => {
  return (
    <>
      <PageHead
        title={seoData.contact.title}
        description={seoData.contact.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">Contact</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link href={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container mb-5">
        <div className="grid md:gap-10 md:mb-0 mb-8 my-10 md:grid-cols-3 grid-cols-1">
          <div className="w-full bg-primary-50 px-4 py-5 rounded">
            <Heading aos="flip-up" text="Get In Touch" content="" />
            <p className="text-sm my-2" data-aos="zoom-in">
              <strong> Kolkata Office : </strong>Shiv Green India Infra
              Developers Pvt. Ltd. 1409, Maduradaha, Near Anandapur P.S.,
              Kolkata - 700107
            </p>
            <p className="text-sm my-2" data-aos="zoom-in">
              <strong>Patna Office :</strong> 402, Surya Bageshwari, West Boring
              Canal Road, Patna - 800001
            </p>
            <p className="text-sm my-2" data-aos="zoom-in">
              <strong>Phone :</strong> +917061777111
            </p>
            <p className="text-sm my-2" data-aos="zoom-in">
              <strong>Email :</strong>info@shivgreenindia.com
            </p>
          </div>
          <div className="col-span-2 md:pt-0 pt-10 overflow-hidden">
            <iframe
              className="mb-8"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.37284654738332!2d88.40410970686752!3d22.505475876371673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02736786dab939%3A0xc9a4fb7b9f183a28!2sShiv%20Green%20India%20-%20Kolkata!5e0!3m2!1sen!2sin!4v1699015757858!5m2!1sen!2sin"
              width="100%"
              height="220"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14390.67290023797!2d85.1206997!3d25.6159387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf858dc724095608!2sShiv%20Green%20India!5e0!3m2!1sen!2sin!4v1637411171590!5m2!1sen!2sin"
              width="100%"
              height="220"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <ContactPage />
    </>
  );
};

export default Contact;
