
import { Button } from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import PropertyShimmer from "../Shimmer/PropertyShimmer";
import { formatAmount, getAssetUri } from "../../utils/helper";
import {Link} from "react-router-dom";
import { PROJECT_DETAIL_ROUTE } from "../../utils/router";
const Project = ({ loading, projects }) => {
  return (
    <div>
      {loading ? (
        <div className="grid xxs:grid-cols-2 sms:grid-cols-3 gap-5">
          <PropertyShimmer />
          <PropertyShimmer />
          <PropertyShimmer />
        </div>
      ) : (
        <div className="grid xxs:grid-cols-2 sms:grid-cols-3 gap-5">
          {projects.map((project, index) => {
            return (
              <Link
                key={index}
                data-aos="fade-up-left"
                to={PROJECT_DETAIL_ROUTE(project.id, project.property_name)}
              >
                <div className="gap-5 relative pb-3 primaryShadow rounded-lg bg-white">
                  <div
                    className={`absolute top-5 left-5 ${
                      project.property_type == "Completed"
                        ? "bg-red-600"
                        : "bg-primary-600"
                    } text-white text-sm py-1 px-2 rounded z-10`}
                  >
                    {project.property_type == "Completed"
                      ? "Sold Out"
                      : "For Sale"}
                  </div>
                  <div className="h-60 overflow-hidden rounded">
                    <img
                      src={getAssetUri("property", project.images[0].image)}
                      className="h-full w-full object-cover hover:scale-110 duration-300"
                    />
                  </div>
                  <div className="flex flex-col px-3 pt-3">
                    <h1 className="text-sm font-bold pr-14">
                      {project.property_name}
                    </h1>
                    <div className="flex mt-1 items-center gap-2">
                      <CiLocationOn className="text-primary-500 text-x2s md:text-xs mr-1" />
                      <p className="text-x2s">
                        {`${project.location.name}, ${project.location.districts.name}, ${project.location.districts.states.name}`}
                      </p>
                    </div>
                    <div className="flex gap-4 items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-primary-600 font-bold text-x2s">
                          {`${formatAmount(project.sale_price) } / ${
                            project.sale_unit_detail
                              ? project.sale_unit_detail.name
                              : ""
                          }`}
                        </span>
                      </div>
                      <Button variant="outlined" size="small" color="primary">
                        View More
                      </Button>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Project;
