import Heading from "../components/Utils/Heading";
import { HOME_ROUTE } from "../utils/router";
import { Link } from "react-router-dom";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import Buy from "../assets/icons/buy.png";
import House from "../assets/icons/house.png";
import Present from "../assets/icons/present.png";
import Rent from "../assets/icons/rent.png";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const About = () => {
  return (
    <>
      <PageHead
        title={seoData.about.title}
        description={seoData.about.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">About Us</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="primaryShadow text-sm my-10 py-10 px-10 mx-3 rounded">
          <h2 data-aos="flip-up" className="text-2xl font-bold text-black pb-5">
            About Shiv Green India
          </h2>
          <p data-aos="flip-up">
            <strong>Shiv Green India Infra Developer’s Pvt. Ltd.</strong> has
            now reached <strong>10</strong>
            successful years in corporate business world . Every moment we are
            evolving newer aesthetics of Prospect’s lifestyle that does not
            incur too much and at the same time it does reflect the pride of our
            clients. The company is managed by a group of highly professional
            individuals who strive to provide their large customer base with
            matchless infrastructure in all fields. The group is known for
            transparent approach and is loved by their clients for delivering
            exactly what was promised to them. Earlier Company is working
            individually with a name
            <strong>Shiv Green India Infra Developer Pvt. Ltd.</strong>
            and has its respective office at
            <strong>
              Shiv Green India Infra Developers Pvt. Ltd. 1409, Maduradaha, Near
              Anandapur P.S., Kolkata - 700107
            </strong>
            at
            <strong>
              West Bengal Kolkata and 402, Surya Bageshwari, West Boring Canal
              Road, Patna - 800001
            </strong>
            At Bihar since 7 years. Today this is a name that needs no
            introduction in the real estate sector. A leading real estate
            development company,
            <strong>Shiv Green India Infra Developer’s Pvt. Ltd.</strong> has a
            diverse portfolio ranging from commercial to residential Plots,
            Flat, Commercial Spaces, Pent Houses as well as Farm Houses in your
            desire location.
          </p>
        </div>
      </div>

      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="What We Provide?"
          content="As Shiv Green India Infra Developer’s Pvt. Ltd. We Provide Various Services Listed Below."
        />
        <div className="lg:grid-cols-4 grid-cols-1 sm:grid-cols-2 grid gap-10 mt-12 py-5 flex-wrap">
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img src={Present} fetchPriority="low" height={200} width={200} />
            <p className="uppercase">Presenting Your Property</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img src={Rent} fetchPriority="low" height={200} width={200} />
            <p className="uppercase">Renting Or Selling</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img src={House} fetchPriority="low" height={200} width={200} />
            <p className="uppercase">Property Exchange</p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <img src={Buy} fetchPriority="low" height={200} width={200} />
            <p className="uppercase">Buying A Property</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
