import  {useState} from "react";
import Input from "../Utils/Input";
import TextArea from "../Utils/TextArea";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { contactFormSchema } from "../../validation/authFormSchema";
import apiRequest from "../../utils/apiRequest";
const Contact = ({heading = ''}) => {
  const [validationError, setValidationError] = useState({
    mobile: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: { mobile: "", password: "" },
    validationSchema: contactFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await apiRequest.contact(values);
        if (response.data.response) {
          toast.success(response.data?.msg);
          formik.resetForm();
        } else {
          toast.error(response.data?.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          setValidationError(error.response.data.errors);
        }
      }
    },
  });
  return (
    <div className="container">
      <div className="primaryShadow mb-5 px-4 py-8 rounded">
        <h4 className="my-4">{heading}</h4>
        <form
          onSubmit={formik.handleSubmit}
          className="grid grid-cols-6 gap-6 w-full"
        >
          <div className="col-span-6">
            <label
              htmlFor="Name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <Input
              type={"text"}
              name={"name"}
              placeholder={"Enter Your Full Name"}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.name}
              serverError={validationError.name}
              touched={formik.touched.name}
            />
          </div>
          <div className="col-span-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <Input
              type={"email"}
              name={"email"}
              placeholder={"Enter Your Email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.email}
              serverError={validationError.email}
              touched={formik.touched.email}
            />
          </div>
          <div className="col-span-6">
            <label
              htmlFor="Mobile"
              className="block text-sm font-medium text-gray-700"
            >
              Mobile
            </label>
            <Input
              type={"phone"}
              name={"phone"}
              placeholder={"Enter Your Mobile No."}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.phone}
              serverError={validationError.phone}
              touched={formik.touched.phone}
            />
          </div>
          <div className="col-span-6">
            <label
              htmlFor="Message"
              className="block text-sm font-medium text-gray-700"
            >
              Message
            </label>
            <TextArea
              type={"message"}
              name={"message"}
              placeholder={"Enter Your message"}
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.message}
              serverError={validationError.message}
              touched={formik.touched.message}
            />
          </div>

          <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
            <button
              type="submit"
              disabled={loading}
              className="inline-block shrink-0 rounded-md border border-accent-500 bg-accent-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
            >
              Book Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
