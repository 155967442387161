import Loader from "../components/Utils/Loader";
import apiRequest from "../utils/apiRequest";
import { getAssetUri } from "../utils/helper";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import { HOME_ROUTE } from "../utils/router";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [length, setLength] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 12;

  const getImages = async () => {
    try {
      const res = await apiRequest.imageGallery(offset);
      if (res.data.response) {
        setImages(res.data.data);
        setLength(res.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreImages = async () => {
    try {
      const res = await apiRequest.imageGallery(offset + limit);
      setOffset(offset + limit);
      if (res.data.response) {
        setImages((prevImages) => [...prevImages, ...res.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <PageHead
        title={seoData.imageGallery.title}
        description={seoData.imageGallery.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">Image Gallery</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                Image Gallery
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {images.length === 0 ? (
        <div className="container my-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="h-60 relative overflow-hidden rounded bg-slate-200">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>

            <div className="h-60 relative overflow-hidden rounded bg-slate-200">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>

            <div className="h-60 relative overflow-hidden rounded bg-slate-200">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={images.length}
          next={fetchMoreImages}
          hasMore={images.length < length}
          loader={<Loader className="pb-5" />}
        >
          <div className="container my-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              {images &&
                images.map((image, key) => {
                  return (
                    <div className="h-60 overflow-hidden rounded" key={key}>
                      <img
                        src={getAssetUri("property", image.image)}
                        className="h-full w-full object-cover hover:scale-110 duration-300"
                        fetchpriority="high" // Custom attribute if needed
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default ImageGallery;
