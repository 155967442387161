import Banner from "../../assets/banner/banner.jpg";
const Hero = () => {
  return (
    <div
      className="relative overflow-hidden h-[500px]  mb-10 "
      style={{
        backgroundSize: "cover",
        backgroundPosition: "cover",
        backgroundImage: `url(${Banner})`,
      }}
    >
      <div className="lg:h-96  overflow-hidden lg:rounded-b-[50%]"></div>
    </div>
  );
};

export default Hero;
