import Heading from "../components/Utils/Heading";
import { FaShippingFast } from "react-icons/fa";
import Hero from "../components/Home/Hero";
import Associate from "../components/Home/Associate";
import { useSelector } from "react-redux";
import Project from "../components/Page/Project";
import { slugToOriginalText } from "../utils/helper";
import Feedback from "../components/Home/Feedback";
import { Link } from "react-router-dom";
import { CITY_ROUTE } from "../utils/router";
import Patna from "../assets/city/patna.jpg";
import Ranchi from "../assets/city/ranchi.webp";
import Kolkata from "../assets/city/kolkata.webp";
import Investment from "../assets/banner/investment.jpg";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const Home = () => {
  const { loading, allProjects, feedback } = useSelector(
    ({ homeRedux }) => homeRedux
  );

  return (
    <div>
      <PageHead
        title={seoData.home.title}
        description={seoData.home.description}
      />
      <Hero />
      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="Latest Properties"
          content="The latest properties are listed below that can be your future home. Lets see what fit for you."
        />
        <Project
          loading={loading}
          projects={allProjects.filter(
            (project) =>
              project.property_type.toLowerCase() !==
              slugToOriginalText("Completed").toLowerCase()
          )}
        />
      </div>
      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="Sold Out Projects"
          content="The latest properties are listed below that can be your future home. Lets see what fit for you."
        />
        <Project
          loading={loading}
          projects={allProjects.filter(
            (project) =>
              project.property_type.toLowerCase() ===
              slugToOriginalText("Completed").toLowerCase()
          )}
        />
      </div>

      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="Simple Steps"
          content="Follow The Simple 3 Steps To Get Your Key"
        />
        <div className="lg:grid-cols-3 grid-cols-1 grid gap-10 mt-12 py-5 flex-wrap">
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <FaShippingFast className="text-5xl text-primary-500" />
            <p className="uppercase">Search For Property</p>
            <p className="text-xs max-w-[250px] text-center">
              Search For Property i.e. Plot, Flat, Commercial Spaces or any
              other property.
            </p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <FaShippingFast className="text-5xl text-primary-500" />
            <p className="uppercase">Select Your Favorite</p>
            <p className="text-xs max-w-[250px] text-center">
              Choose Your Favorite Property From Our Hug Listed Property.
            </p>
          </div>
          <div
            data-aos="zoom-in"
            className="flex flex-col items-center justify-center gap-3"
          >
            <FaShippingFast className="text-5xl text-primary-500" />
            <p className="uppercase">Take Your Key</p>
            <p className="text-xs max-w-[250px] text-center">
              Take Your Key, After Booking You Done Your Selection
            </p>
          </div>
        </div>
      </div>

      <div className="container overflow-hidden my-9 py-5">
        <Heading
          className={"py-3"}
          aos="flip-up"
          text="Property By City"
          content="Property by most popular cities and location are below by filter."
        />
        <div className="grid xxs:grid-cols-2 sms:grid-cols-3 gap-5">
          <Link to={CITY_ROUTE("kolkata")}>
            <div
              data-aos="fade-up-left"
              className=" gap-5 relative  pb-3 primaryShadow rounded-lg bg-white"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black  z-10">
                <div className="flex items-end justify-center h-full">
                  <h2 className="text-lg text-white uppercase pb-2">Kolkata</h2>
                </div>
              </div>
              <div className="h-60 overflow-hidden rounded">
                <img
                  src={Kolkata}
                  className="h-full w-full object-cover hover:scale-110 duration-300"
                />
              </div>
            </div>{" "}
          </Link>
          <Link to={CITY_ROUTE("patna")}>
            <div
              data-aos="fade-up-left"
              className=" gap-5 relative  pb-3 primaryShadow rounded-lg bg-white"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black  z-10">
                <div className="flex items-end justify-center h-full">
                  <h2 className="text-lg text-white uppercase pb-2">Patna</h2>
                </div>
              </div>
              <div className="h-60 overflow-hidden rounded">
                <img
                  src={Patna}
                  className="h-full w-full object-cover hover:scale-110 duration-300"
                />
              </div>
            </div>{" "}
          </Link>
          <Link to={CITY_ROUTE("ranchi")}>
            <div
              data-aos="fade-up-left"
              className=" gap-5 relative  pb-3 primaryShadow rounded-lg bg-white"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black  z-10">
                <div className="flex items-end justify-center h-full">
                  <h2 className="text-lg text-white uppercase pb-2">Ranchi</h2>
                </div>
              </div>
              <div className="h-60 overflow-hidden rounded">
                <img
                  src={Ranchi}
                  className="h-full w-full object-cover hover:scale-110 duration-300"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Heading
        className={"py-3 mb-6"}
        aos="flip-up"
        text="Client Video Feedback"
        content="Experience the stories and testimonials from our valued clients. Their feedback highlights the positive impact our services have had on their businesses and lives."
      />
      {feedback && feedback.length > 0 && <Feedback videos={feedback} />}
      <div
        className="h-48 my-16 overflow-hidden max-w-[1200px] px-4 mx-auto"
        data-aos="zoom-in"
      >
        <div
          className="rounded-[10px] aspect-square"
          style={{
            backgroundImage: `url(${Investment})`,
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <Heading
        className={"py-3 mb-6"}
        aos="flip-up"
        text="Clients & Associates"
        content="Our clients span across various industries and regions, each trusting us to deliver exceptional results tailored to their specific needs. "
      />
      <Associate />
    </div>
  );
};

export default Home;
