import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../Home/categorySelection.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import "../Home/slick.css";
import Client from "../../assets/images/client.jpg";
export const CustomNextArrow = ({ onClick }) => {
  return (
    <div className="nextButton">
      <IconButton onClick={onClick}>
        <FiChevronRight style={{ fontSize: "24px", color: "#fff" }} />
      </IconButton>
    </div>
  );
};

export const CustomPrevArrow = ({ onClick }) => {
  return (
    <div className="prevButton">
      <IconButton onClick={onClick}>
        <FiChevronLeft style={{ fontSize: "24px", color: "#fff" }} />
      </IconButton>
    </div>
  );
};

const Associate = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative container mb-6">
      <Slider {...settings}>
        <div className="mr-3">
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
        <div>
          <img src={Client} alt="client" />
        </div>
      </Slider>
    </div>
  );
};

export default Associate;
