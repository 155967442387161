import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import City from "../pages/City";
import Contact from "../pages/Contact";
import FindPlot from "../pages/FindPlot";
import ImageGallery from "../pages/ImageGallery";
import MediaCoverage from "../pages/MediaCoverage";
import MissionVision from "../pages/MissionVision";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Project from "../pages/Project";
import ProjectDetail from "../pages/ProjectDetail";
import RefundPolicy from "../pages/RefundPolicy";
import VideoGallery from "../pages/VideoGallery";
import ProjectByStatus from "../pages/ProjectByStatus";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/city/:city" element={<City />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/find-plot" element={<FindPlot />} />
      <Route path="/image-gallery" element={<ImageGallery />} />
      <Route path="/media-coverage" element={<MediaCoverage />} />
      <Route path="/mission-vision" element={<MissionVision />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/projects" element={<Project />} />
      <Route path="/projects/:status" element={<ProjectByStatus />} />
      <Route path="/project/:id/:title" element={<ProjectDetail />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/video-gallery" element={<VideoGallery />} />
    </Routes>
  );
}
