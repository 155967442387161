import { createSlug } from "./helper";

export const HOME_ROUTE = "/";
export const ABOUT_ROUTE = "/about";
export const CONTACT_ROUTE = "/contact";
export const MISSION_VISION_ROUTE = "/mission-vision";
export const PROJECTS_ROUTE = "/projects";
export const FIND_PLOT_ROUTE = "/find-plot";
export const REFUND_ROUTE = "/refund-policy";
export const PRIVACY_ROUTE = "/privacy-policy";
export const IMAGE_GALLERY_ROUTE = "/image-gallery";
export const VIDEO_GALLERY_ROUTE = "/video-gallery";
export const MEDIA_COVERAGE_ROUTE = "/media-coverage";
export const PROJECT_DETAIL_ROUTE = (id, title) => {
  return `/project/${id}/${createSlug(title)}`;
};
export const PROJECT_STATUS_ROUTE = (status) => {
  return `/projects/${createSlug(status)}`;
};
export const CITY_ROUTE = (city) => {
  return `/city/${createSlug(city)}`;
};
