import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + 'api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const apiRequest = {
  getProject: () =>
    instance({
      method: 'GET',
      url: 'getProject',
    }),
  projectDetail: (id) =>
    instance({
      method: 'GET',
      url: `projectDetail/${id}`,
    }),
  contact: (data) =>
    instance({
      method: 'POST',
      url: 'contact',
      data,
    }),
  getPlotList: (id) =>
    instance({
      method: 'GET',
      url: `plotList?id=${id}`,
    }),
  imageGallery: (offset) =>
    instance({
      method: 'GET',
      url: `imageGallery?offset=${offset}`,
    }),
  videoGallery: (offset) =>
    instance({
      method: 'GET',
      url: `clientFeedback?offset=${offset}`,
    }),
};

export default apiRequest;
