import { useState, useEffect } from "react";
import { RiShareLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const FloatingShareButton = () => {
  const [currentPageUrl, setcurrentPageUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    console.log(window.location.href);
    setcurrentPageUrl(window.location.href);
  }, [location.pathname]);

  const sharePage = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this page!",
          url: currentPageUrl,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.error("Error while using Web share API:", err);
        });
    } else {
      alert("Browser doesn't support the Web Share API !");
    }
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        onClick={sharePage}
        className="bg-primary-500 hover:bg-accent-600 text-white font-bold rounded-full h-10 w-10 flex items-center justify-center shadow-lg"
        aria-label="Share Button"
      >
        <RiShareLine size={24} />
      </button>
    </div>
  );
};

export default FloatingShareButton;
