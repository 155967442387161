import Heading from "../components/Utils/Heading";
import { HOME_ROUTE } from "../utils/router";
import { Link } from "react-router-dom";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import Mission from "../assets/images/mission.jpg";
import PageHead from "../components/Meta/PageHead";
import seoData from "../utils/json/seo.json";
const MissionVision = () => {
  return (
    <>
      <PageHead
        title={seoData.mission.title}
        description={seoData.mission.description}
      />
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <h2 className="text-2xl font-bold text-white">Mission & Vision</h2>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                Mission & Vision
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="grid gap-10 my-10 md:grid-cols-2">
          <div>
            <Heading
              className={"py-3"}
              aos="flip-up"
              text="We Provide Lovable Experiment in the Real Estate Field"
              content=""
            />
            <p className="text-sm my-10" data-aos="zoom-in">
              Our mission is to keep developing organic competency in the
              provision of quality services in the domains of real estate and
              infrastructure to our valued client base. We believe that without
              innovation there is no sure success which is why we are constantly
              engaged in the quest of self-improvement and the quest for
              perfection directly devoteed to all our respective Prospects. We
              believe in the fact that our every Prospects has the right to own
              an aesthetic and comfortable living and working space that does
              not overstrain his / her budget. It is our goal to expand Shiv
              Green Indai Infra Developer’s Pvt. Ltd. as a top leading company
              across the nation and world wide on the basis of our non stop hard
              work and endless efforts which will leads to 100% satisfaction for
              all our Prospects.
            </p>
          </div>
          <div className="flex items-center justify-center  rounded p-3">
            <img data-aos="zoom-in" className="rounded" src={Mission} />
          </div>
        </div>
        <p className="text-sm my-10" data-aos="zoom-in">
          Shiv Green Indai Infra Developer’s Pvt. Ltd. strongly committed to
          become the first real estate brand name that comes to mind whenever
          the phrase “Quality real estate projects with countless satisfaction
          of all our Prospects” is mentioned.
        </p>
        <p className="text-sm my-10" data-aos="zoom-in">
          The ongoing mission of the company is to assist buyers and sellers in
          making the BEST real estate decisions possible by providing relevant
          knowledge, collaboration, professionalism and a memorable experience.
          We believe that without innovation there is no sure success which is
          why we are constantly engaged in the quest of self-improvement and the
          quest for complete perfection for our Prospects. It is our primary
          duty to serve people with secure and correct plots supported withal
          high-end technological advancements, basic facilities like Schools,
          Hospitals, Malls, Play Ground , Temple , Community Hall etc. and
          exteriors which surpass their expectations what they have always
          desired in the domain of real estate.
        </p>
        <p className="text-sm my-10" data-aos="zoom-in">
          We are motivated towards providing comprehensive real estate solutions
          to our valued customers and help them achieve good value for their
          money in return.
        </p>
        <p className="text-sm my-10" data-aos="zoom-in">
          So deep is our commitment towards quality for that we constantly
          engage ourselves in finding new ways to serve our customers on a
          national as well as global level. It is our prerogative to also ensure
          that we meet the environmental supportive norms and are earth friendly
          while creating any landmark project.
        </p>
        <p className="text-sm my-10" data-aos="zoom-in">
          We have continuously tried to set improved standards of quality
          construction and continually contribute towards offering more
          beautiful and more fulfilled lives to our prestigious customers who
          are based all over India.
        </p>

        <Heading
          className={"py-3"}
          aos="flip-up"
          text="Our Vision"
          content=""
        />
        <p className="text-sm my-10" data-aos="zoom-in">
          Our vision is to contribute significantly to build a new India and
          become the world’s most valuable real estate company. We follow a
          systematic approach for complete paper work, Project Planning,
          Designing, Resource Setup and Completion, for Client satisfaction
          within stipulated time frame. We are one of the leading Real Estate
          Company of India, established 03 years ago to provide superior quality
          residential and commercial plots to our respective Prospects. Our
          company Sustained efforts to enhance customer value and quality.
        </p>

        <p className="text-sm my-10" data-aos="zoom-in">
          Ethical and professional services. Compliance and respect for all
          community, environmental and legal requirement so that Shiv Green
          India Infra Developer’s Pvt. Ltd could major role in construction of
          world-class real estate projects that could stand as benchmarks to
          India’s pride and assist our city, our state and our country in
          reaching the pinnacle of infrastructural and real estate growth.
        </p>

        <p className="text-sm my-10" data-aos="zoom-in">
          We envision the that the Indian subcontinent shine with a repertoire
          of marvelous infrastructure and a stunning skyline. In our own unique
          way, we aspire to play a pivotal role in constructing that skyline
        </p>
      </div>
    </>
  );
};

export default MissionVision;
