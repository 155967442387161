import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { HOME_ROUTE } from "../utils/router";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import {
  convertYouTubeUrlToEmbedUrl,
  formatAmount,
  getAssetUri,
} from "../utils/helper";
import { CiLocationOn } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiRequest from "../utils/apiRequest";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Contact from "../components/Page/Contact";
import ProjectDetailShimmer from "../components/Shimmer/ProjectDetailShimmer";
import { CustomNextArrow, CustomPrevArrow } from "../components/Home/Associate";
import BreadCrump from "../assets/banner/breadcrump.jpg";
import seoData from "../utils/json/seo.json";
import PageHead from "../components/Meta/PageHead";
import AdobeViewer from "../components/AdobeViewer";
const ProjectDetail = () => {
  const { id } = useParams();
  const sliderRef = useRef(null);
  const [projectDetail, setProjectDetail] = useState();
  const [open, setOpen] = useState(false);
  const [plots, setPlots] = useState();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const mainSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    slidesToScroll: 1,
    arrows: true,
  };

  const handlePlotSearch = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setPlots(projectDetail.plots);
    } else {
      setPlots(
        projectDetail.plots.filter((plot) =>
          plot.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };
  const getProjectDetail = async () => {
    try {
      const response = await apiRequest.projectDetail(id);
      if (response.data.response) {
        setProjectDetail(response.data.project);
        setPlots(response.data.project.plots);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getProjectDetail();
    }
  }, [id]);
  return (
    <>
      {projectDetail && Object.keys(projectDetail).length > 0 ? (
        <>
          <PageHead
            title={
              projectDetail.property_name
                ? projectDetail.property_name
                : "" + seoData.concat.title
            }
            description={seoData.about.description}
          />
          <div
            style={{
              backgroundImage: `url(${BreadCrump})`,
              width: "100%",
              height: "200px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
              <h2 className="md:text-2xl text-xl font-bold text-white">
                {projectDetail.property_name}
              </h2>
              <nav aria-label="breadcrumb" className="w-max">
                <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
                  <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                    <Link href={HOME_ROUTE} className="opacity-60">
                      Home
                    </Link>
                    <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                      /
                    </span>
                  </li>
                  <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                    <Link href={HOME_ROUTE} className="opacity-60">
                      Projects
                    </Link>
                    <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                      /
                    </span>
                  </li>
                  <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500 text-wrap">
                    {projectDetail.property_name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="container py-10 px-4">
            <div className="flex items-center justify-between primaryShadow md:flex-row flex-col py-5 rounded px-4">
              <div>
                <h1 className="font-bold font-2xl text-primary-900">
                  {projectDetail.property_name}
                </h1>
                <div className="flex mt-1 md:items-center items-start gap-2">
                  <CiLocationOn className="text-primary-800 text-xl md:text-xs mr-1" />
                  <p className="text-x2s">
                    {`${projectDetail.location.name}, ${projectDetail.location.districts.name}, ${projectDetail.location.districts.states.name}`}
                  </p>
                </div>
              </div>

              <div className="flex mt-1 items-center gap-2 flex-col">
                <p>For Sale</p>
                <p className="text-2xl font-bold text-rose-600">
                  {`${formatAmount(projectDetail.sale_price)} / ${
                    projectDetail.sale_unit_detail
                      ? projectDetail.sale_unit_detail.name
                      : ""
                  }`}
                </p>
              </div>
            </div>
          </div>

          <div className="container pb-10 px-4">
            <div className="grid gap-10 md:grid-cols-2 grid-cols-1">
              <div className="primaryShadow px-2 rounded">
                <h4 data-aos="flip-up" className="text-lg font-bold my-3">
                  Gallery
                </h4>
                <Slider ref={sliderRef} {...mainSliderSettings}>
                  {projectDetail.images
                    .filter((image) => image.show)
                    .map((image, index) => (
                      <div key={index}>
                        <div className="relative w-full aspect-video md:h-[300px] text-white overflow-hidden -z-10">
                          <div className="absolute w-full mx-auto aspect-video md:h-[300px] inset-0 overflow-hidden">
                            <img
                              src={getAssetUri("property", image.image)}
                              alt={"Slide" + index}
                              className="aspect-video object-center w-full h-full"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
              <div className="px-2 rounded">
                <h4 data-aos="flip-up" className="text-lg font-bold my-3">
                  Features
                </h4>
                <PropertyFeatures features={projectDetail.features} />
              </div>
            </div>

            {projectDetail.videos.filter(
              (video) => video.video_type === "Project"
            ).length > 0 && (
              <>
                <h4 data-aos="flip-up" className="text-lg font-bold my-6">
                  Plot Construction
                </h4>
                <Slider {...settings}>
                  {projectDetail.videos
                    .filter((video) => video.video_type === "Project")
                    .map((video, index) => (
                      <div key={index} className="rounded px-4">
                        <div className="border-slate-400 h-[300px] overflow-hidden">
                          <iframe
                            width="100%"
                            height="300px"
                            src={convertYouTubeUrlToEmbedUrl(video.link)}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ))}
                </Slider>
              </>
            )}

            {projectDetail.videos.filter(
              (video) => video.video_type === "Feedback"
            ).length > 0 && (
              <>
                <h4 data-aos="flip-up" className="text-lg font-bold my-6">
                  Customer Feedback
                </h4>
                <Slider {...settings}>
                  {projectDetail.videos
                    .filter((video) => video.video_type === "Feedback")
                    .map((video, index) => (
                      <div key={index} className="rounded px-4">
                        <div className="border-slate-400 h-[300px] overflow-hidden">
                          <iframe
                            width="100%"
                            height="300px"
                            src={convertYouTubeUrlToEmbedUrl(video.link)}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ))}
                </Slider>
              </>
            )}
          </div>
          <div className="container md:mx-auto pb-10 primaryShadow rounded-[10px]">
            <h4 data-aos="flip-up" className="text-lg font-bold my-3">
              Find Your Plot
            </h4>
            <div>
              <input
                type="text"
                id="plot_name"
                onChange={handlePlotSearch}
                className="bg-white h-8 w-full px-4 group focus:outline-none focus:border-primary-500 border-[2px] border-slate-500 text-xs mb-3"
                placeholder="Search Here"
                required
              />
            </div>
            <div className="flex flex-wrap justify-center">
              {plots &&
                plots.map((plot, index) => (
                  <DetailBox key={index} plot={plot} />
                ))}
            </div>
          </div>

          <div className="container px-4 pb-10 my-10">
            <div className="grid gap-10 md:grid-cols-2 grid-cols-1">
              <div className="primaryShadow mb-4 px-2 rounded">
                <h4 data-aos="flip-up" className="text-lg font-bold my-3">
                  Features
                </h4>
                <div className="flex flex-col gap-5 ml-5">
                  <div className="flex items-center gap-5">
                    <PiArrowFatLinesRightFill className="text-primary-500 text-2xl" />
                    <div className="flex flex-col" data-aos="flip-up">
                      <h5 className="m-0 font-bold text-sm">Location:</h5>
                      <p className="text-sm">
                        {`${projectDetail.location.name}, ${projectDetail.location.districts.name}, ${projectDetail.location.districts.states.name}`}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5">
                    <PiArrowFatLinesRightFill className="text-primary-500 text-2xl" />
                    <div className="flex flex-col" data-aos="flip-up">
                      <h5 className="m-0 font-bold text-sm">Landmark:</h5>
                      <p className="text-sm">{projectDetail.landmark}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-5">
                    <PiArrowFatLinesRightFill className="text-primary-500 text-2xl" />
                    <div className="flex flex-col" data-aos="flip-up">
                      <h5 className="m-0 font-bold text-sm">Near By KM:</h5>
                      <p className="text-sm">{projectDetail.near_place}</p>
                    </div>
                  </div>
                </div>
                <p className="ml-5 text-sm line-clamp-3 mt-5 mx-5 mb-2">
                  {projectDetail.description}
                </p>
                <div className="flex justify-end pr-4">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setOpen(true)}
                  >
                    View More
                  </Button>
                </div>
              </div>
              <div className="primaryShadow mb-4 px-2 rounded">
                <h4 data-aos="flip-up" className="text-lg font-bold my-3">
                  Location
                </h4>
                <iframe
                  src={`https://maps.google.com/maps?q=${projectDetail.latitude}, ${projectDetail.longitude}&z=15&output=embed`}
                  width="100%"
                  height="380"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Property Description</DialogTitle>
            <DialogContent>
              <DialogContentText>{projectDetail.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {projectDetail.maps && (
            <AdobeViewer
              fileUrl={getAssetUri("propertyMap", projectDetail.maps.map)}
            />
          )}
          <div className="mt-10">
            <Contact heading="Book Your Visit Request" />
          </div>
        </>
      ) : (
        <ProjectDetailShimmer />
      )}
    </>
  );
};
export default ProjectDetail;
const PropertyFeatures = ({ features }) => {
  const classes = [
    "bg-blue-100",
    "bg-gray-100",
    "bg-red-100",
    "bg-green-100",
    "bg-yellow-100",
    "bg-indigo-100",
    "bg-purple-100",
  ];
  const getRandomClass = () => {
    return classes[Math.floor(Math.random() * classes.length)];
  };

  return (
    <ul className=" grid md:grid-cols-4 grid-cols-2 gap-3">
      {features.split("$").map((feature, index) => (
        <span
          key={index}
          className={`${getRandomClass()} text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded`}
        >
          {feature}
        </span>
      ))}
    </ul>
  );
};
const DetailBox = ({ plot }) => {
  return (
    <div
      data-aos="flip-up"
      className="py-2 relative px-4 border-slate-300 border-[1px] text-center text-white w-[90px]"
      style={{
        backgroundColor: ` ${
          plot.status == "Investment"
            ? "#b2102f"
            : getPlotStatusColor(
                getPlotStatus(plot.status, plot.booking?.booking_detail)
              )
        }`,
      }}
    >
      {plot.investment != null || plot.status == "Investment" ? (
        <div className="star-container p-2">
          <div className="star-mark">
            <FaStar className="text-sm text-accent-500" />
          </div>
        </div>
      ) : (
        ""
      )}
      <h2 className="mb-2 text-x2s">{plot.name}</h2>
      <p className="mb-1 text-x2s">
        {`   ${parseFloat(plot.length).toFixed(2)} / ${parseFloat(
          plot.width
        ).toFixed(2)}`}
      </p>
      <p className="mb-1 text-x2s">
        {plot.total_area}
        {getFirstDigitFromString(plot.unit.name)}
      </p>
      <p className="mb-1 text-x2s">
        {getPlotStatus(plot.status, plot.booking?.booking_detail)}
      </p>
    </div>
  );
};
function getFirstDigitFromString(str) {
  return str.charAt(0);
}
function getPlotStatus(status, bookingDetail = "") {
  if (!bookingDetail || bookingDetail.status == "Rejected") {
    switch (status) {
      case "Sold":
        return "Booked";
      case "Booking Done":
        return "Booked";
      case "Hold":
        return "Hold";
      case "Registry Done":
        return `Registry Done`;
      default:
        return "Available";
    }
  } else {
    switch (bookingDetail?.status) {
      case "Registry Done":
        return "Registry Done";
      case "Hold":
        return "Hold";
      case "Rejected":
        return "Available";
      case "Verified":
        return "Booked";
      default:
        return "Booked";
    }
  }
}
function getPlotStatusColor(status) {
  switch (status) {
    case "Hold":
      return "grey";
    case "Investment":
      return "green";
    case "Booked":
      return "red";
    case "Registry <br> Done":
      return "blue";
    case "Registry Done":
      return "blue";
    default:
      return "green";
  }
}
