import { useEffect, useState } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  ABOUT_ROUTE,
  CONTACT_ROUTE,
  FIND_PLOT_ROUTE,
  HOME_ROUTE,
  IMAGE_GALLERY_ROUTE,
  MEDIA_COVERAGE_ROUTE,
  MISSION_VISION_ROUTE,
  PROJECTS_ROUTE,
  PROJECT_STATUS_ROUTE,
  VIDEO_GALLERY_ROUTE,
} from "../../utils/router";
import { Button, ClickAwayListener } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "../../redux/homeSlice";
import { Link } from "react-router-dom";
import Logo from '../../assets/logo/logo.png';
const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const handleToggleNavBar = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleCloseNavBar = () => {
    setIsNavOpen(false);
  };
  const navLinks = [
    {
      name: "Home",
      link: HOME_ROUTE,
    },
    {
      name: "About",
      icon: <IoIosArrowDown />,
      submenu: [
        {
          name: "About Us",
          link: ABOUT_ROUTE,
          icon: <IoIosArrowForward />,
        },
        {
          name: "Mission & Vision",
          link: MISSION_VISION_ROUTE,
          icon: <IoIosArrowForward />,
        },
      ],
    },
    {
      name: "Projects",
      icon: <IoIosArrowDown />,
      submenu: [
        {
          name: "Upcoming Projects",
          link: PROJECT_STATUS_ROUTE("Upcoming"),
          icon: <IoIosArrowForward />,
        },
        {
          name: "Running Projects",
          link: PROJECTS_ROUTE,
          icon: <IoIosArrowForward />,
        },
        {
          name: "Completed Projects",
          link: PROJECT_STATUS_ROUTE("Completed"),
          icon: <IoIosArrowForward />,
        },
        {
          name: "Construction Projects",
          link: PROJECT_STATUS_ROUTE("Running"),
          icon: <IoIosArrowForward />,
        },
      ],
    },
    {
      name: "Gallery",
      icon: <IoIosArrowDown />,
      submenu: [
        {
          name: "Image Gallery",
          link: IMAGE_GALLERY_ROUTE,
          icon: <IoIosArrowForward />,
        },
        {
          name: "Video Gallery",
          link: VIDEO_GALLERY_ROUTE,
          icon: <IoIosArrowForward />,
        },
        {
          name: "Media Coverage",
          link: MEDIA_COVERAGE_ROUTE,
          icon: <IoIosArrowForward />,
        },
      ],
    },
    {
      name: "Contact",
      link: CONTACT_ROUTE,
    },
  ];
  const {projects } = useSelector(
    ({ homeRedux }) => homeRedux
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if(projects.length === 0)
    dispatch(getProject());
  }, [projects]);
  return (
    <>
      <nav className="flex  gap-3 sm:gap-0 items-center justify-between border-b-[1px] border-slate-300 py-3  px-3">
        <img className="h-14 w-28" src={Logo} alt="logo" />
        <ul className="md:flex hidden  gap-5 py-4 items-center justify-center text-sm">
          {navLinks.map((item, index) => {
            return !item.icon ? (
              <li key={index}>
                <Link
                  to={item.link}
                  className="flex py-1 items-center justify-between text-sm hover:text-primary"
                >
                  <p>{item.name}</p>
                </Link>
              </li>
            ) : (
              <li key={index} className="relative group ">
                <div className="flex py-1 items-center justify-between text-sm hover:text-primary cursor-pointer">
                  <p className="mr-3">{item.name}</p>
                  {item.icon}
                </div>
                <div className="absolute z-10 left-0  py-5">
                  <ul className=" bg-white py-3 searchShadow w-[200px] hidden group-hover:block">
                    {item.submenu.map((submenu, index) => {
                      return (
                        <li key={index} className="hover:bg-green-300 px-3">
                          <Link
                            to={submenu.link}
                            className="flex py-1 items-center justify-between text-sm"
                          >
                            <p>{submenu.name}</p>
                            {submenu.icon}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="flex items-center gap-2 py-5 ">
          <a href="https://shivgreenindia.com/admin/login" >
            <Button
              size="small"
              style={{ color: "#000", fontSize: "10px" }}
              startIcon={<AiOutlineLogin />}
            >
              <p>Login</p>
            </Button>
          </a>
          <Link to={FIND_PLOT_ROUTE}>
            <Button
              className="md:flex hidden"
              variant="contained"
              size="small"
              style={{ color: "#fff", fontSize: "10px" }}
              startIcon={<CiSearch />}
            >
              <p>Find Plot</p>
            </Button>
          </Link>
          <div className="flex items-center gap-10">
            <ClickAwayListener onClickAway={handleCloseNavBar}>
              <div>
                <GiHamburgerMenu
                  onClick={handleToggleNavBar}
                  className="text-grey-500 text-2xl font-bold md:hidden block cursor-pointer"
                />

                <div
                  className={`fixed top-0 w-[250px] bg-slate-500 h-full z-10 lg:hidden block duration-500 ${
                    isNavOpen ? " left-0" : " left-[-260px]"
                  }`}
                >
                  <div className=" h-screen flex-col justify-between border-e  bg-white">
                    <div className="px-4 py-6 h-full overflow-x-auto">
                      <span className="grid h-10 w-32 place-content-center rounded-lg bg-gray-100 text-xs text-gray-600 py-2">
                        <img src={Logo} className="h-10" alt="Logo" />
                      </span>
                      <Link to={FIND_PLOT_ROUTE}>
                        <Button
                          variant="contained"
                          className="mt-10"
                          size="small"
                          style={{ color: "#fff", fontSize: "10px" }}
                          startIcon={<CiSearch />}
                        >
                          <p>Find Plot</p>
                        </Button>
                      </Link>
                      <ul className="mt-6 space-y-1 h-full">
                        {navLinks.map((item, index) => {
                          return !item.icon ? (
                            <Link
                              key={index}
                              to={item.link}
                              className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                              {item.name}
                            </Link>
                          ) : (
                            <li key={index}>
                              <details>
                                <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-primary-100 hover:text-gray-700">
                                  <span className="text-sm font-medium">
                                    {item.name}
                                  </span>
                                  <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </summary>
                                <ul className="mt-2 space-y-1 px-4 h-full">
                                  {item.submenu.map((submenu, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={submenu.link}
                                          className="rounded-lg px-4 py-2 text-xs font-semibold text-gray-500 hover:bg-primary-100 hover:text-gray-700 flex items-center justify-between"
                                        >
                                          {submenu.name}
                                          {submenu.icon}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </details>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
