import { HOME_ROUTE } from "../../utils/router";
import {Link} from "react-router-dom";
import BreadCrump from "../../assets/banner/breadcrump.jpg";
const ProjectDetailShimmer = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${BreadCrump})`,
          width: "100%",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-center flex-col h-full text-white bg-gradient-to-r from-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0.6)]">
          <div className="h-[10px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <nav aria-label="breadcrumb" className="w-max">
            <ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Home
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <Link to={HOME_ROUTE} className="opacity-60">
                  Projects
                </Link>
                <span className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
                  /
                </span>
              </li>
              <li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
                <div className="h-[10px] w-[100px] relative overflow-hidden rounded bg-slate-200">
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-10 px-4">
        <div className="flex items-center justify-between primaryShadow md:flex-row flex-col py-5 rounded px-4">
          <div>
            <h1 className="font-bold font-2xl text-primary-900">
              <div className="h-[20px] w-[200px] relative overflow-hidden rounded bg-slate-200">
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
              </div>
            </h1>
            <div className="flex mt-1 md:items-center items-start gap-2">
              <div className="h-[20px] w-[200px] relative overflow-hidden rounded bg-slate-200">
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
              </div>
            </div>
          </div>

          <div className="flex mt-1 items-center gap-2 flex-col">
            <p>For Sale</p>
            <div className="h-[10px] w-[200px] relative overflow-hidden rounded bg-slate-200">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-10 px-4">
        <div className="grid gap-10 md:grid-cols-2 grid-cols-1">
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-3 gap-3">
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
            <span
              className={`bg-indigo-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded h-8`}
            ></span>
          </div>
        </div>
      </div>

      <div className="container px-4 pb-10">
        <div className="grid gap-10 md:grid-cols-3 grid-cols-1">
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
        </div>
        <div className="grid gap-10 md:grid-cols-3 grid-cols-1 mt-10">
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="h-[300px] relative overflow-hidden rounded bg-slate-200">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
        </div>

        <div className="h-[300px] mt-10 relative overflow-hidden rounded bg-slate-200">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailShimmer;
