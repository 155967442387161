import { useEffect, useState } from "react";

const AdobeViewer = ({ fileUrl }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => setError("Failed to load Adobe SDK script");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      const interval = setInterval(() => {
        if (window.AdobeDC) {
          clearInterval(interval);

          try {
            const adobeDCView = new window.AdobeDC.View({
              clientId: "00e7c3a961584e749396388df250db7f",
              divId: "adobe-dc-view",
            });

            adobeDCView.previewFile(
              {
                content: {
                  location: {
                    url: fileUrl,
                  },
                },
                metaData: {
                  fileName: "Plot Map.pdf",
                },
              },
              {
                defaultViewMode: "FIT_WIDTH",
                showAnnotationTools: false,
                showDownloadPDF: true,
                showPrintPDF: true,
              }
            );
          } catch (e) {
            setError("Failed to initialize Adobe PDF viewer");
          }
        }
      }, 100); // Check every 100ms

      // Cleanup interval on unmount
      return () => clearInterval(interval);
    }
  }, [isScriptLoaded, fileUrl]);

  return (
    <div className="container">
      {error ? (
        <p>{error}</p>
      ) : isScriptLoaded ? (
        <div id="adobe-dc-view" style={{ height: "100vh" }}></div>
      ) : (
        <p>Loading PDF viewer...</p>
      )}
    </div>
  );
};
export default AdobeViewer;
